import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartProps {
  options: any;
  series: any;
  width?: string | number;
  height?: string | number;
  onClick?: (value : any) => void;
  events?: string[];
}

const ChartPie: React.FC<ChartProps> = ({
  options,
  series,
  width = '100%',
  height = '100%',
  onClick,
  events,
}) => {
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      width={width}
      height={height}
      onClick={onClick}
      events={events}
    />
  );
};

export default ChartPie;

import axios from 'axios';

export const apiLink =
  'http://jubileudistribuidora119026.protheus.cloudtotvs.com.br:4050/rest';
export const apiLinkTst =
  'http://jubileudistribuidora119027.protheus.cloudtotvs.com.br:4050/rest';
export const apilocal = 'http://localhost:9998/rest';

export const protheusUser = 'admin';
const protheusPasswordProd = '#2023prod@';
const protheusPasswordTst = '123';

const api = axios.create({
  baseURL: apiLink,
});

export const protheusPassword = protheusPasswordProd;

export default api;

import React, { useContext, useEffect, useState } from 'react';

import * as Style from './styles';
import { Col, Row } from 'react-bootstrap';

import { ThemeContext } from '../../contexts/ThemeContext';
import { lightTheme, darkTheme } from '../../themes';
import ChartColumn from '../../charts/column';
import {
  dataColumn2,
  lineOptions,
  lineSeries,
  optionColumn3,
  optionPie,
} from '../../dummy';

import Dropdown from 'react-bootstrap/Dropdown';

import ChartLine from '../../charts/line';
import ModalComponent from '../../components/modal';
import { CurrencyFormat } from '../../utils/currencyFormat';
import { capitalize } from '../../utils/nameFormat';
import { useMediaQuery } from 'react-responsive';
import api, { protheusPassword, protheusUser } from '../../services/api';
import ChartPie from '../../charts/pie';
import { data } from '@syncfusion/ej2';

interface Props {
  ano: string;
  mes: string;
}

interface Conta {
  banco: string;
  agencia: string;
  conta: string;
  saldo: number;
}

interface ReceberPagar {
  vencimento: string;
  valor: number;
}

interface DetalhametoReceber {
  razaoSocial: string;
  NomeFantasia: string;
  numeroTitulo: string;
  vencimento: string;
  valor: number;
  representante: string;
  cidade: string;
  uf: string;
}

interface DetalhametoPagar {
  razaoSocial: string;
  NomeFantasia: string;
  numeroTitulo: string;
  vencimento: string;
  valor: number;
  representante: string;
  cidade: string;
  uf: string;
  pagar: string;
}

interface DetalhametoRecebidoDoDia {
  razaoSocial: string;
  NomeFantasia: string;
  numeroTitulo: string;
  vencimento: string;
  valor: number;
  representante: string;
  cidade: string;
  uf: string;
  pagar: string;
}

interface DetalhametoRecebidoNaSemana {
  razaoSocial: string;
  NomeFantasia: string;
  numeroTitulo: string;
  vencimento: string;
  valor: number;
  representante: string;
  cidade: string;
  uf: string;
  pagar: string;
}

var vencimento: string;
let semana: number = 0;
let tipo: number = 0;
let dia: string = '';

const FinanceiroDia: React.FC<Props> = ({ ano, mes }) => {
  const [detalhamentoPagar, setDetalhamentoPagar] = useState<any>([]);
  const [detalhamentoReceber, setDetalhamentoReceber] = useState<any>([]);
  const [detalhamentoNoVencimento, setDetalhamentoNoVencimento] = useState<any>(
    []
  );
  const [detalhamentoCobranca, setDetalhamentoCobranca] = useState<any>([]);
  const [detalhamentoAdiantado, setDetalhamentoAdiantado] = useState<any>([]);
  const [detalhamentoNaoPagos, setDetalhamentoNaoPagos] = useState<any>([]);
  const [detalhamentoEntradaAmanha, setDetalhamentoEntradaAmanha] =
    useState<any>([]);

  const [detalhamentoPagosEmDia, setDetalhamentoPagosEmDia] = useState<any>([]);
  const [detalhamentoRecebidoDeCobranca, setDetalhamentoRecebidoDeCobranca] =
    useState<any>([]);
  const [detalhamentoDeixaramDePagar, setDetalhamentoDeixaramDePagar] =
    useState<any>([]);

  const { theme } = useContext(ThemeContext);
  const themeContext = theme === 'light' ? lightTheme : darkTheme;

  const userData = localStorage.getItem('userdata');
  const user = userData ? JSON.parse(userData) : null;

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);
  const [showModal7, setShowModal7] = useState(false);
  const [showModal8, setShowModal8] = useState(false);
  const [showModal9, setShowModal9] = useState(false);
  const [showModal10, setShowModal10] = useState(false);

  const [labelPie, setLabelPie] = useState<any>([]);
  const [valorPie, setValorPie] = useState<any>([]);

  const [labelPieFechamento, setLabelPieFechamento] = useState<any>([]);
  const [valorPieFechamento, setValorPieFechamento] = useState<any>([]);
  const [parametrosAnteriores, setParametrosAnteriores] = useState({
    ano: '',
    mes: '',
    semana: '',
    tipo: '',
    dia: '',
  });

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const [saldo, setSaldo] = useState<any>([]);
  const [receber, setReceber] = useState<any>([]);
  const [pagar, setPagar] = useState<any>([]);

  const MyCard = ({ children }: any) => {
    return (
      <Col>
        <Style.CardDash border={theme} className="h-100">
          <Style.CardDashBody>{children}</Style.CardDashBody>
        </Style.CardDash>
      </Col>
    );
  };

  console.log(ano, mes);
  useEffect(() => {
    if (ano !== 'Ano') {
      fetchData();
    }
  }, [ano, mes, semana, tipo, dia]);

  const fetchData = async () => {
    const credentials = btoa(`${protheusUser}:${protheusPassword}`);
    const response = await api.get(
      `/WSAPP22?ano=${ano}&mes=${mes}&semana=${semana}&tipo=${tipo}&dia=${dia}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    const json: any = response.data;

    let labelPie: any[] = [];
    let valorPie: any[] = [];

    let labelPieFechamento: any[] = [];
    let valorPieFechamento: any[] = [];

    if (json.status.code === '#200') {
      setSaldo(json.saldo);
      setPagar(json.pagar);
      setReceber(json.receber);
      setDetalhamentoPagar(json.detalhamentoPagar);
      setDetalhamentoReceber(json.detalhamentoReceber);
      setDetalhamentoNoVencimento(json.detalhamentoNoVencimento);
      setDetalhamentoCobranca(json.detalhamentoCobranca);
      setDetalhamentoAdiantado(json.detalhamentoAdiantado);
      setDetalhamentoNaoPagos(json.detalhamentoNaoPagos);
      setDetalhamentoEntradaAmanha(json.detalhamentoEntradaAmahnha);
      setDetalhamentoPagosEmDia(json.detalhamentoPagosEmDia);
      setDetalhamentoRecebidoDeCobranca(json.detalhamentoRecebidoDeCobranca);
      setDetalhamentoDeixaramDePagar(json.detalhamentoDeixaramDePagar);

      labelPie = json.recebidoDoDia[0];
      setLabelPie(labelPie);

      valorPie = json.recebidoDoDia[1];
      setValorPie(valorPie);
      console.log(valorPie);

      labelPieFechamento = json.fechamentoDaSemana[0];
      setLabelPieFechamento(labelPieFechamento);

      valorPieFechamento = json.fechamentoDaSemana[1];
      setValorPieFechamento(valorPieFechamento);
    }
    console.log(valorPieFechamento);
  };

  const somaSaldo = () => {
    const saldos = saldo.map((conta: Conta) => conta.saldo);
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  //   useEffect(() => {

  //       fetchData();
  //       console.log('Atualizando dados com base em:', {
  //         ano,
  //         mes,
  //         semana,
  //         tipo,
  //         dia,
  //         vencimento,
  //       });
  //     }
  //   , [ano, mes, semana, tipo,dia, vencimento]);

  const somaSaldoReceber = () => {
    const saldos = receber.map((receber: ReceberPagar) => receber.valor);
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoPagar = () => {
    const saldos = pagar.map((pagar: ReceberPagar) => pagar.valor);
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoReceber = () => {
    const saldos = detalhamentoReceber.map(
      (receber: DetalhametoReceber) => receber.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoPagar = () => {
    const saldos = detalhamentoPagar.map(
      (pagar: DetalhametoPagar) => pagar.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };
  const somaSaldoDetalhamentoEntradaAmanha = () => {
    const saldos = detalhamentoEntradaAmanha.map(
      (amanha: DetalhametoRecebidoDoDia) => amanha.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoCobranca = () => {
    const saldos = detalhamentoCobranca.map(
      (cobranca: DetalhametoRecebidoDoDia) => cobranca.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoAdiantado = () => {
    const saldos = detalhamentoAdiantado.map(
      (adiantado: DetalhametoRecebidoDoDia) => adiantado.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoNaoPagos = () => {
    const saldos = detalhamentoNaoPagos.map(
      (naoPagos: DetalhametoRecebidoDoDia) => naoPagos.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoNoVencimento = () => {
    const saldos = detalhamentoNoVencimento.map(
      (noVencimento: DetalhametoRecebidoDoDia) => noVencimento.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoPagosEmDia = () => {
    const saldos = detalhamentoNoVencimento.map(
      (noVencimento: DetalhametoRecebidoNaSemana) => noVencimento.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoRecebidoDeCobrança = () => {
    const saldos = detalhamentoRecebidoDeCobranca.map(
      (cobranca: DetalhametoRecebidoNaSemana) => cobranca.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaSaldoDetalhamentoDeixaramDePagar = () => {
    const saldos = detalhamentoDeixaramDePagar.map(
      (deixaramDePagar: DetalhametoRecebidoNaSemana) => deixaramDePagar.valor
    );
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const handlerClickPagar = (pagar: ReceberPagar) => {
    dia = pagar.vencimento;
    tipo = 2;

    // Converta os valores atuais para strings antes de compará-los
    const anoString = String(ano);
    const mesString = String(mes);
    const semanaString = String(semana);
    const tipoString = String(tipo);
    const diaString = String(dia);

    // Verificar se os dados já foram buscados para os parâmetros atuais
    if (
      parametrosAnteriores.ano !== anoString ||
      parametrosAnteriores.mes !== mesString ||
      parametrosAnteriores.semana !== semanaString ||
      parametrosAnteriores.tipo !== tipoString ||
      parametrosAnteriores.dia !== diaString
    ) {
      fetchData();
      // Atualizar os valores anteriores para os parâmetros atuais
      setParametrosAnteriores({
        ano: anoString,
        mes: mesString,
        semana: semanaString,
        tipo: tipoString,
        dia: diaString,
      });
    }
    setShowModal2(true);
    dia = '';
    tipo = 0;
  };

  const handlerClickReceber = (receber: ReceberPagar) => {
    dia = receber.vencimento;
    tipo = 1;

    // Converta os valores atuais para strings antes de compará-los
    const anoString = String(ano);
    const mesString = String(mes);
    const semanaString = String(semana);
    const tipoString = String(tipo);
    const diaString = String(dia);

    // Verificar se os dados já foram buscados para os parâmetros atuais
    if (
      parametrosAnteriores.ano !== anoString ||
      parametrosAnteriores.mes !== mesString ||
      parametrosAnteriores.semana !== semanaString ||
      parametrosAnteriores.tipo !== tipoString ||
      parametrosAnteriores.dia !== diaString
    ) {
      fetchData();
      // Atualizar os valores anteriores para os parâmetros atuais
      setParametrosAnteriores({
        ano: anoString,
        mes: mesString,
        semana: semanaString,
        tipo: tipoString,
        dia: diaString,
      });
    }
    setShowModal1(true);
    dia = '';
    tipo = 0;
  };

  const handlerSemana = (semanaAux: number) => {
    semana = semanaAux;

    fetchData();
  };

  const handlerPie = (event: any) => {
    const pathElement = event.target; // Supondo que 'event.target' seja uma referência ao elemento <path> em questão
    const parentElement = pathElement.parentNode; // ou pathElement.parentElement;

    let label = parentElement.getAttribute('seriesName');

    if (event.target.getAttribute('data:value')) {
      if (label.includes('AMANHÃ')) {
        setShowModal3(true);
      } else if (label.includes('VENCIMENTO')) {
        setShowModal4(true);
      } else if (label.includes('COBRANÇA')) {
        setShowModal5(true);
      } else if (label.includes('ADIANTADO')) {
        setShowModal6(true);
      } else if (label.includes('PAGOS')) {
        setShowModal7(true);
      }
    }
  };

  const handlerPieFechamento = (event: any) => {
    const pathElement = event.target; // Supondo que 'event.target' seja uma referência ao elemento <path> em questão
    const parentElement = pathElement.parentNode; // ou pathElement.parentElement;

    let label = parentElement.getAttribute('seriesName');

    if (event.target.getAttribute('data:value')) {
      if (label.includes('PAGOS')) {
        setShowModal8(true);
      } else if (label.includes('RECEBIDOS')) {
        setShowModal9(true);
      } else if (label.includes('DEIXARAM')) {
        setShowModal10(true);
      }
    }
  };

  return (
    <>
      <Row className="g-4">
        <Col md={12}>
          <Row
            xs={1}
            md={2}
            className="g-4"
            key={Math.floor(Math.random() * 100)}
          >
            <MyCard key={Math.floor(Math.random() * 100)}>
              <Style.TextDash size={10} weight="bold" overflow="hidden">
                <Style.TextDash
                  size={20}
                  weight="bold"
                  align="center"
                  paddinBottom={20}
                >
                  SALDO DO DIA
                </Style.TextDash>
                <Style.ContHorizontal>
                  <Style.TextDash
                    size={14}
                    weight="bold"
                    style={{ width: '10%' }}
                  >
                    Banco
                  </Style.TextDash>
                  <Style.TextDash
                    size={14}
                    weight="bold"
                    style={{ width: '12%' }}
                  >
                    Agência
                  </Style.TextDash>
                  <Style.TextDash
                    size={14}
                    weight="bold"
                    style={{ width: '17%' }}
                  >
                    Conta
                  </Style.TextDash>
                  <Style.TextDash
                    size={14}
                    weight="bold"
                    style={{ width: '27%' }}
                  >
                    Saldo Atual
                  </Style.TextDash>
                </Style.ContHorizontal>
              </Style.TextDash>
              <Style.Separator />
              <div style={{ height: 400, overflow: 'auto' }}>
                {saldo.map((saldo: any) => {
                  return (
                    <>
                      <Style.ContHorizontal style={{ marginTop: '10px' }}>
                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '10%' }}
                        >
                          {capitalize(saldo.banco)}
                        </Style.TextDash>
                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '12%' }}
                        >
                          {saldo.agencia}
                        </Style.TextDash>
                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '17%' }}
                        >
                          {saldo.conta}
                        </Style.TextDash>
                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '27%' }}
                        >
                          {CurrencyFormat(saldo.saldo)}
                        </Style.TextDash>
                      </Style.ContHorizontal>
                    </>
                  );
                })}
                <Style.Separator />
                <Style.ContHorizontalTotal>
                  <Style.TextDash size={18} weight="bold" paddingRigh={70}>
                    Total Geral:
                  </Style.TextDash>
                  <Style.TextDash size={18} weight="bold" paddingRigh={60}>
                    {CurrencyFormat(somaSaldo())}
                  </Style.TextDash>
                </Style.ContHorizontalTotal>
              </div>
            </MyCard>

            {/* ------------------------------------------------------------------------------ */}

            <MyCard>
              <Style.TextDash size={10} weight="bold" overflow="hidden">
                <Style.TextDash
                  size={20}
                  weight="bold"
                  align="center"
                  paddinBottom={130}
                >
                  RECEBIDO DO DIA
                </Style.TextDash>

                <ChartPie
                  options={optionPie(themeContext, labelPie)}
                  series={valorPie}
                  events={['click']}
                  onClick={(event) => handlerPie(event)}
                />
                <Style.TextDash
                  size={20}
                  weight="bold"
                  align="center"
                  paddinBottom={10}
                >
                  {/* RECEBIDO DO DIA */}
                </Style.TextDash>
              </Style.TextDash>
            </MyCard>

            {/* ------------------------------------------------------------------------------ */}

            <MyCard key={Math.floor(Math.random() * 100)}>
              <Style.TextDash
                size={20}
                weight="bold"
                align="center"
                paddinTop={0}
                paddinBottom={10}
                paddingRigh={30}
              >
                DEFLUXO - PREVISÃO
              </Style.TextDash>

              <Dropdown>
                <Dropdown.Toggle variant={theme} id="dropdown-basic">
                  Semana
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={() => handlerSemana(1)}
                  >
                    Semana 1
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-2"
                    onClick={() => handlerSemana(2)}
                  >
                    Semana 2
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-3"
                    onClick={() => handlerSemana(3)}
                  >
                    Semana 3
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-4"
                    onClick={() => handlerSemana(4)}
                  >
                    Semana 4
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-5"
                    onClick={() => handlerSemana(5)}
                  >
                    Semana 5
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-5"
                    onClick={() => handlerSemana(0)}
                  >
                    Todas
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* --------------------------------------------------------------------------------------- */}

              <Style.ContHorizontal>
                <div style={{ width: '49%' }}>
                  <MyCard key={Math.floor(Math.random() * 100)}>
                    <Style.TextDash size={18} weight="bold" overflow="hidden">
                      <Style.TextDash
                        size={18}
                        weight="bold"
                        align="center"
                        paddinBottom={10}
                      >
                        A RECEBER
                      </Style.TextDash>
                      <Style.ContHorizontal>
                        <Style.TextDash
                          size={14}
                          weight="bold"
                          style={{ width: '50%' }}
                        >
                          Vencimento
                        </Style.TextDash>
                        <Style.TextDash
                          size={14}
                          weight="bold"
                          style={{ width: '50%' }}
                        >
                          Soma de Saldo
                        </Style.TextDash>
                      </Style.ContHorizontal>
                    </Style.TextDash>
                    <Style.Separator />
                    <div style={{ height: 400, overflow: 'auto' }}>
                      {receber.map((receber: any) => {
                        return (
                          <>
                            <Style.ContHorizontal style={{ marginTop: '10px' }}>
                              <Style.TextDash
                                size={14}
                                weight="normal"
                                style={{ width: '50%' }}
                              >
                                <tr
                                  onClick={() => handlerClickReceber(receber)}
                                >
                                  {capitalize(
                                    receber.vencimento.slice(6, 8) +
                                      '/' +
                                      receber.vencimento.slice(4, 6) +
                                      '/'
                                  ) + receber.vencimento.slice(0, 4)}
                                </tr>
                              </Style.TextDash>
                              <Style.TextDash
                                size={14}
                                weight="normal"
                                style={{ width: '50%' }}
                              >
                                <tr
                                  onClick={() => handlerClickReceber(receber)}
                                >
                                  {CurrencyFormat(receber.valor)}
                                </tr>
                              </Style.TextDash>
                            </Style.ContHorizontal>
                          </>
                        );
                      })}
                      <Style.Separator />
                      <Style.ContHorizontalTotal>
                        <Style.TextDash
                          size={18}
                          weight="bold"
                          paddingRigh={20}
                        >
                          Total Geral:
                        </Style.TextDash>
                        <Style.TextDash
                          size={18}
                          weight="bold"
                          paddingRigh={20}
                        >
                          {CurrencyFormat(somaSaldoReceber())}
                        </Style.TextDash>
                      </Style.ContHorizontalTotal>
                    </div>
                  </MyCard>
                </div>

                {/* ------------------------------------------------------------------------------- */}

                <div>
                  <MyCard key={Math.floor(Math.random() * 100)}>
                    <Style.TextDash size={18} weight="bold" overflow="hidden">
                      <Style.TextDash
                        size={18}
                        weight="bold"
                        align="center"
                        paddinBottom={10}
                      >
                        A PAGAR
                      </Style.TextDash>
                      <Style.ContHorizontal>
                        <Style.TextDash
                          size={14}
                          weight="bold"
                          style={{ width: '50%' }}
                        >
                          Vencimento
                        </Style.TextDash>
                        <Style.TextDash
                          size={14}
                          weight="bold"
                          style={{ width: '50%' }}
                        >
                          Soma de Saldo
                        </Style.TextDash>
                      </Style.ContHorizontal>
                    </Style.TextDash>
                    <Style.Separator />
                    <div style={{ height: 400, overflow: 'auto' }}>
                      {pagar.map((pagar: any) => {
                        return (
                          <>
                            <Style.ContHorizontal style={{ marginTop: '10px' }}>
                              <Style.TextDash
                                size={14}
                                weight="normal"
                                style={{ width: '50%' }}
                              >
                                <tr onClick={() => handlerClickPagar(pagar)}>
                                  {capitalize(
                                    pagar.vencimento.slice(6, 8) +
                                      '/' +
                                      pagar.vencimento.slice(4, 6) +
                                      '/'
                                  ) + pagar.vencimento.slice(0, 4)}
                                </tr>
                              </Style.TextDash>
                              <Style.TextDash
                                size={14}
                                weight="normal"
                                style={{ width: '50%' }}
                              >
                                <tr onClick={() => handlerClickPagar(pagar)}>
                                  {CurrencyFormat(pagar.valor)}
                                </tr>
                              </Style.TextDash>
                            </Style.ContHorizontal>
                          </>
                        );
                      })}
                      <Style.Separator />
                      <Style.ContHorizontalTotal>
                        <Style.TextDash
                          size={18}
                          weight="bold"
                          paddingRigh={20}
                        >
                          Total Geral:
                        </Style.TextDash>
                        <Style.TextDash
                          size={18}
                          weight="bold"
                          paddingRigh={20}
                        >
                          {CurrencyFormat(somaSaldoPagar())}
                        </Style.TextDash>
                      </Style.ContHorizontalTotal>
                    </div>
                  </MyCard>
                </div>
              </Style.ContHorizontal>
            </MyCard>

            {/* ------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------ */}
            <MyCard>
              <Style.TextDash size={10} weight="bold" overflow="hidden">
                <Style.TextDash
                  size={20}
                  weight="bold"
                  align="center"
                  paddinBottom={130}
                >
                  FECHAMENTO DA SEMANA
                </Style.TextDash>

                <ChartPie
                  options={optionPie(themeContext, labelPieFechamento)}
                  series={valorPieFechamento}
                  events={['click']}
                  onClick={(event) => handlerPieFechamento(event)}
                />
                <Style.TextDash
                  size={20}
                  weight="bold"
                  align="center"
                  paddinBottom={10}
                >
                  {/* RECEBIDO DO DIA */}
                </Style.TextDash>
              </Style.TextDash>
            </MyCard>
          </Row>
        </Col>
      </Row>

      {/* ----------------------------------------------------------------------------------------- */}
      <ModalComponent
        show={showModal1}
        onHide={() => {
          setShowModal1(false);
          setDetalhamentoReceber([]); // Limpa o array ao fechar o modal
        }}
        title={'DETALHAMENTO A RECEBER'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Representante
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoReceber.map((receber: any) => {
                return (
                  <>
                    <Style.ContHorizontal style={{ marginTop: '5px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '35%', paddingRight: '5px' }}
                      >
                        <tr>{receber.razaoSocial}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '23%', paddingRight: '5px' }}
                      >
                        <tr>{receber.NomeFantasia}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{receber.numeroTitulo}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>
                          {capitalize(
                            receber.vencimento.slice(6, 8) +
                              '/' +
                              receber.vencimento.slice(4, 6) +
                              '/'
                          ) + receber.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(receber.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>{receber.representante}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '15%', paddingRight: '5px' }}
                      >
                        <tr>{receber.cidade}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%' }}
                      >
                        <tr>{receber.uf}</tr>
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoReceber())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
          </div>
        }
        Tools={''}
      />

      {/* ----------------------------------------------------------------------------------------- */}
      <ModalComponent
        show={showModal2}
        onHide={() => {
          setShowModal2(false);
          setDetalhamentoPagar([]); // Limpa o array ao fechar o modal
        }}
        title={'DETALHAMENTO A PAGAR'}
        Body={
          <div style={{ height: '600px' }}>
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoPagar.map((pagar: any) => {
                return (
                  <>
                    <Style.ContHorizontal style={{ marginTop: '5px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '35%', paddingRight: '5px' }}
                      >
                        <tr>{pagar.razaoSocial}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '23%', paddingRight: '5px' }}
                      >
                        <tr>{pagar.NomeFantasia}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{pagar.numeroTitulo}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>
                          {capitalize(
                            pagar.vencimento.slice(6, 8) +
                              '/' +
                              pagar.vencimento.slice(4, 6) +
                              '/'
                          ) + pagar.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(pagar.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '15%', paddingRight: '5px' }}
                      >
                        <tr>{pagar.cidade}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%' }}
                      >
                        <tr>{pagar.uf}</tr>
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoPagar())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />

      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal3}
        onHide={() => {
          setShowModal3(false);
        }}
        title={'ENTRADA AMANHÃ'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
                {/* <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Pagar
                </Style.TextDash> */}
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoEntradaAmanha.map((amanha: any, checked: any) => {
                return (
                  <>
                    <Style.ContHorizontal style={{ marginTop: '5px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '35%', paddingRight: '5px' }}
                      >
                        <tr>{amanha.razaoSocial}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '23%', paddingRight: '5px' }}
                      >
                        <tr>{amanha.NomeFantasia}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{amanha.numeroTitulo}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>
                          {capitalize(
                            amanha.vencimento.slice(6, 8) +
                              '/' +
                              amanha.vencimento.slice(4, 6) +
                              '/'
                          ) + amanha.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(amanha.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '15%', paddingRight: '5px' }}
                      >
                        <tr>{amanha.cidade}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%' }}
                      >
                        <tr>{amanha.uf}</tr>
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoEntradaAmanha())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />

      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal4}
        onHide={() => {
          setShowModal4(false);
        }}
        title={'NO VENCIMENTO'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
                {/* <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Pagar
                </Style.TextDash> */}
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoNoVencimento.map((vencimento: any, checked: any) => {
                return (
                  <>
                    <Style.ContHorizontal style={{ marginTop: '5px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '35%', paddingRight: '5px' }}
                      >
                        <tr>{vencimento.razaoSocial}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '23%', paddingRight: '5px' }}
                      >
                        <tr>{vencimento.NomeFantasia}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{vencimento.numeroTitulo}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>
                          {capitalize(
                            vencimento.vencimento.slice(6, 8) +
                              '/' +
                              vencimento.vencimento.slice(4, 6) +
                              '/'
                          ) + vencimento.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(vencimento.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '15%', paddingRight: '5px' }}
                      >
                        <tr>{vencimento.cidade}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%' }}
                      >
                        <tr>{vencimento.uf}</tr>
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoNoVencimento())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />

      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal5}
        onHide={() => {
          setShowModal5(false);
        }}
        title={'COBRANÇA'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
                {/* <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Pagar
                </Style.TextDash> */}
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoCobranca.map((cobranca: any, checked: any) => {
                return (
                  <>
                    <Style.ContHorizontal style={{ marginTop: '5px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '35%', paddingRight: '5px' }}
                      >
                        <tr>{cobranca.razaoSocial}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '23%', paddingRight: '5px' }}
                      >
                        <tr>{cobranca.NomeFantasia}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{cobranca.numeroTitulo}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>
                          {capitalize(
                            cobranca.vencimento.slice(6, 8) +
                              '/' +
                              cobranca.vencimento.slice(4, 6) +
                              '/'
                          ) + cobranca.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(cobranca.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '15%', paddingRight: '5px' }}
                      >
                        <tr>{cobranca.cidade}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%' }}
                      >
                        <tr>{cobranca.uf}</tr>
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoCobranca())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />

      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal6}
        onHide={() => {
          setShowModal6(false);
        }}
        title={'ADIANTADO'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
                {/* <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Pagar
                </Style.TextDash> */}
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoAdiantado.map((adiantado: any, checked: any) => {
                return (
                  <>
                    <Style.ContHorizontal style={{ marginTop: '5px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '35%', paddingRight: '5px' }}
                      >
                        <tr>{adiantado.razaoSocial}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '23%', paddingRight: '5px' }}
                      >
                        <tr>{adiantado.NomeFantasia}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{adiantado.numeroTitulo}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>
                          {capitalize(
                            adiantado.vencimento.slice(6, 8) +
                              '/' +
                              adiantado.vencimento.slice(4, 6) +
                              '/'
                          ) + adiantado.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(adiantado.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '15%', paddingRight: '5px' }}
                      >
                        <tr>{adiantado.cidade}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%' }}
                      >
                        <tr>{adiantado.uf}</tr>
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoAdiantado())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />

      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal7}
        onHide={() => {
          setShowModal7(false);
        }}
        title={'NÃO PAGOS'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
                {/* <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Pagar
                </Style.TextDash> */}
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoNaoPagos.map((naoPagos: any, checked: any) => {
                return (
                  <>
                    <Style.ContHorizontal style={{ marginTop: '5px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '35%', paddingRight: '5px' }}
                      >
                        <tr>{naoPagos.razaoSocial}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '23%', paddingRight: '5px' }}
                      >
                        <tr>{naoPagos.NomeFantasia}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{naoPagos.numeroTitulo}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>
                          {capitalize(
                            naoPagos.vencimento.slice(6, 8) +
                              '/' +
                              naoPagos.vencimento.slice(4, 6) +
                              '/'
                          ) + naoPagos.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(naoPagos.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '15%', paddingRight: '5px' }}
                      >
                        <tr>{naoPagos.cidade}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%' }}
                      >
                        <tr>{naoPagos.uf}</tr>
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoNaoPagos())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />

      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal8}
        onHide={() => {
          setShowModal8(false);
        }}
        title={'PAGOS EM DIA'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
                {/* <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Pagar
                </Style.TextDash> */}
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoPagosEmDia.map((pagos: any, checked: any) => {
                return (
                  <>
                    <Style.ContHorizontal style={{ marginTop: '5px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '35%', paddingRight: '5px' }}
                      >
                        <tr>{pagos.razaoSocial}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '23%', paddingRight: '5px' }}
                      >
                        <tr>{pagos.NomeFantasia}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{pagos.numeroTitulo}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '13%', paddingRight: '5px' }}
                      >
                        <tr>
                          {capitalize(
                            pagos.vencimento.slice(6, 8) +
                              '/' +
                              pagos.vencimento.slice(4, 6) +
                              '/'
                          ) + pagos.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '12%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(pagos.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '15%', paddingRight: '5px' }}
                      >
                        <tr>{pagos.cidade}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%' }}
                      >
                        <tr>{pagos.uf}</tr>
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoPagosEmDia())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />
      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal9}
        onHide={() => {
          setShowModal9(false);
        }}
        title={'RECEBIDO DE COBRANÇA'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
                {/* <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Pagar
                </Style.TextDash> */}
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoRecebidoDeCobranca.map(
                (cobranca: any, checked: any) => {
                  return (
                    <>
                      <Style.ContHorizontal style={{ marginTop: '5px' }}>
                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '35%', paddingRight: '5px' }}
                        >
                          <tr>{cobranca.razaoSocial}</tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '23%', paddingRight: '5px' }}
                        >
                          <tr>{cobranca.NomeFantasia}</tr>
                        </Style.TextDash>
                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '12%', paddingRight: '5px' }}
                        >
                          <tr>{cobranca.numeroTitulo}</tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '13%', paddingRight: '5px' }}
                        >
                          <tr>
                            {capitalize(
                              cobranca.vencimento.slice(6, 8) +
                                '/' +
                                cobranca.vencimento.slice(4, 6) +
                                '/'
                            ) + cobranca.vencimento.slice(0, 4)}
                          </tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '12%', paddingRight: '5px' }}
                        >
                          <tr>{CurrencyFormat(cobranca.valor)}</tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '15%', paddingRight: '5px' }}
                        >
                          <tr>{cobranca.cidade}</tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '5%' }}
                        >
                          <tr>{cobranca.uf}</tr>
                        </Style.TextDash>
                      </Style.ContHorizontal>
                      <Style.Separator />
                    </>
                  );
                }
              )}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoRecebidoDeCobrança())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />
      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal10}
        onHide={() => {
          setShowModal10(false);
        }}
        title={'DEIXARAM DE PAGAR'}
        Body={
          <div style={{ height: '600px' }}>
            {/* <MyCard key={Math.floor(Math.random() * 100)}> */}
            <Style.TextDash size={18} weight="bold" overflow="hidden">
              <Style.ContHorizontal>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '35%' }}
                >
                  Razão Social
                </Style.TextDash>
                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '23%' }}
                >
                  Nome Fantasia
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Num. Título
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '13%' }}
                >
                  Vecimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '12%' }}
                >
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Cidade
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
                {/* <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Pagar
                </Style.TextDash> */}
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {detalhamentoDeixaramDePagar.map(
                (deixaramDePagar: any, checked: any) => {
                  return (
                    <>
                      <Style.ContHorizontal style={{ marginTop: '5px' }}>
                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '35%', paddingRight: '5px' }}
                        >
                          <tr>{deixaramDePagar.razaoSocial}</tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '23%', paddingRight: '5px' }}
                        >
                          <tr>{deixaramDePagar.NomeFantasia}</tr>
                        </Style.TextDash>
                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '12%', paddingRight: '5px' }}
                        >
                          <tr>{deixaramDePagar.numeroTitulo}</tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '13%', paddingRight: '5px' }}
                        >
                          <tr>
                            {capitalize(
                              deixaramDePagar.vencimento.slice(6, 8) +
                                '/' +
                                deixaramDePagar.vencimento.slice(4, 6) +
                                '/'
                            ) + deixaramDePagar.vencimento.slice(0, 4)}
                          </tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '12%', paddingRight: '5px' }}
                        >
                          <tr>{CurrencyFormat(deixaramDePagar.valor)}</tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '15%', paddingRight: '5px' }}
                        >
                          <tr>{deixaramDePagar.cidade}</tr>
                        </Style.TextDash>

                        <Style.TextDash
                          size={14}
                          weight="normal"
                          style={{ width: '5%' }}
                        >
                          <tr>{deixaramDePagar.uf}</tr>
                        </Style.TextDash>
                      </Style.ContHorizontal>
                      <Style.Separator />
                    </>
                  );
                }
              )}

              <Style.ContHorizontalTotal>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  Total Geral:
                </Style.TextDash>
                <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                  {CurrencyFormat(somaSaldoDetalhamentoDeixaramDePagar())}
                </Style.TextDash>
              </Style.ContHorizontalTotal>
            </div>
            {/* </MyCard> */}
          </div>
        }
        Tools={''}
      />
      {/* ----------------------------------------------------------------------------------------- */}
    </>
  );
};

export default FinanceiroDia;

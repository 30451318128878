import api, { protheusPassword, protheusUser } from './api';

interface ApiResponse {
  status: {
    code: string;
    message: string;
  };
  hasNext: boolean;
  result: any;
}

export const fetchData = async () => {
  let auxResult: any = [];
  let returnResult: any = [];
  const credentials = btoa(`${protheusUser}:${protheusPassword}`);

  const response = await api.get(`/WSAPP19`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
  const json: ApiResponse = response.data;

  if (json.status.code === '#200') {
    auxResult = json.result.reduce((acc: any, current: any) => {
      const x = acc.find((item: { id: any }) => item.id === current.id);
      return !x ? acc.concat([current]) : acc;
    }, []);

    const sortedResult = [...auxResult].sort((a, b) => {
      return a.id.localeCompare(b.id);
    });

    sortedResult.map((_, index: number) => {
      sortedResult[index].mark = false;
    });

    returnResult = [...sortedResult];
  }

  return returnResult;
};

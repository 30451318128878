import React, { useEffect, useContext, useState } from 'react';

import * as Style from './styles';
import { Col, Row } from 'react-bootstrap';

import { ThemeContext } from '../../contexts/ThemeContext';
import { lightTheme, darkTheme } from '../../themes';
import {
  lineOptions,
  lineSeries,
  pieOptions,
  pieOptions2,
  pieSeries2,
} from '../../dummy';

import Button from 'react-bootstrap/Button';

import ChartLine from '../../charts/line';
import ChartPie from '../../charts/pie';
import ModalComponent from '../../components/modal';
import api, { protheusPassword, protheusUser } from '../../services/api';
import { CurrencyFormat } from '../../utils/currencyFormat';
import { capitalize, divide } from 'lodash';

interface Props {
  ano: string;
  mes: string;
}

interface Inadimplencia {
  valor: number;
  estado: string;
}

interface Recebidos {
  valor: number;
  tipo: string;
}

interface RecebidosDeCobranca {
  valor: number;
  tipo: string;
}

interface PagosRecebidos {
  valor: number;
  tipo: string;
}

interface InadimplenciaRepresentante {
  valor: number;
  representante: string;
}

interface InadimplenciaAno {
  valor: number;
  descricao: string;
}

interface Detalhamento {
  nf: string;
  tipo: string;
  parcela: string;
  razaoSocial: string;
  valor: number;
  saldoJuros: number;
  vencimento: string;
  representante: string;
  uf: string;
  descricao?: string;
  ano?: string;
}

const FinanceiroMes: React.FC<Props> = ({ ano, mes }) => {
  const { theme } = useContext(ThemeContext);
  const themeContext = theme === 'light' ? lightTheme : darkTheme;

  const userData = localStorage.getItem('userdata');
  const user = userData ? JSON.parse(userData) : null;

  const [showModal, setShowModal] = useState(false);

  const [labelPie, setLabelPie] = useState<any>([]);
  const [valorPie, setValorPie] = useState<any>([]);

  const [labelPie2, setLabelPie2] = useState<any>([]);
  const [valorRecebidos, setRecebidos] = useState<any>([]);
  const [valorRecebidosDeCobranca, setRecebidosDeCobranca] = useState<any>([]);
  const [itensFiltrados, setItensFiltrados] = useState<any>([]);
  const [valorPagosRecebidos, setPagosRecebidos] = useState<any>([]);
  const [inadimplentes, setInadimplentes] = useState<any>([]);
  const [valorInadimplenciaRepresentante, setInadimplenciaRepresentante] =
    useState<any>([]);

  const [valorInadimplenciaAno, setInadimplenciaAno] = useState<any>([]);

  const [labelPieRecebidos, setLabelRecebidos] = useState<any>([]);
  const [valorPieRecebidos, setValorPieRecebidos] = useState<any>([]);

  const [labelPieRecebidosDeCobranca, setLabelRecebidosDeCobranca] =
    useState<any>([]);
  const [valorPieRecebidosDeCobranca, setValorPieRecebidosDeCobranca] =
    useState<any>([]);

  const [inadimplenciaDetalhamento, setInadimplenciaDetalhamento] =
    useState<any>([]);
  const [recebidosDetalhamento, setRecebidosDetalhamento] = useState<any>([]);
  const [recebidos, setRecebidosetalhamento] = useState<any>([]);

  const [PagosRecebidosDetalhamento, setPagosRecebidosDetalhamento] =
    useState<any>([]);

  const [InadimplentesDetalhamento, setInadimplentesDetalhamento] =
    useState<any>([]);

  const [
    InadimplenciaRepresentanteDetalhamento,
    setInadimplenciaRepresentanteDetalhamento,
  ] = useState<any>([]);

  const [InadimplenciaAnoDetalhamento, setInadimplenciaAnoDetalhamento] =
    useState<any>([]);

  const [recebidosDeCobrancaDetalhamento, setRecebidosDeCobrancaDetalhamento] =
    useState<any>([]);

  const [labelPiePagosRecebidos, setLabelPagosRecebidos] = useState<any>([]);
  const [valorPiePagosRecebidos, setValorPiePagosRecebidos] = useState<any>([]);

  const [labelPieInadimplentes, setLabelInadimplentes] = useState<any>([]);
  const [valorPieInadimplentes, setValorPieInadimplentes] = useState<any>([]);

  const [labelPieRecebidosPagosDeCobranca, setLabelRecebidosPagosDeCobranca] =
    useState<any>([]);
  const [
    valorPieRecebidosPagosDeCobranca,
    setValorPieRecebidosPagosDeCobranca,
  ] = useState<any>([]);

  const [
    labelPieInadimplenciaRepresentante,
    setLabelPieInadimplenciaRepresentante,
  ] = useState<any>([]);
  const [
    valorPieInadimplenciaRepresentante,
    setValorPieInadimplenciaRepresentante,
  ] = useState<any>([]);

  const [labelPieInadimplenciaAno, setLabelPieInadimplenciaAno] = useState<any>(
    []
  );
  const [valorPieInadimplenciaAno, setValorPieInadimplenciaAno] = useState<any>(
    []
  );

  useEffect(() => {
    if (ano !== 'Ano') {
      fetchData();
    }
  }, [ano, mes]);

  const fetchData = async () => {
    const credentials = btoa(`${protheusUser}:${protheusPassword}`);
    const response = await api.get(`/WSAPP23?ano=${ano}&mes=${mes}`, {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const json: any = response.data;

    // console.log(json);

    let labelPie: any[] = [];
    let valorPie: any[] = [];

    let labelPieFechamento: any[] = [];
    let valorPieFechamento: any[] = [];

    if (json.status.code === '#200') {
      setInadimplenciaDetalhamento(json.inadimplenciaDetalhamento);
      setRecebidosDetalhamento(json.recebidosDetalhamento);
      setRecebidos(json.recebidos);

      setRecebidosDeCobranca(json.recebidosDeCobranca);
      setRecebidosDeCobrancaDetalhamento(json.recebidosDeCobrancaDetalhamento);
      console.log(json.recebidosDeCobranca);
      console.log(json);

      console.log(json.pagoRecebidos);
      setPagosRecebidos(json.pagoRecebidos);
      setPagosRecebidosDetalhamento(json.pagoRecebidosDetalhamento);
      setInadimplenciaRepresentante(json.inadimplenciaRepresentante);
      setInadimplenciaRepresentanteDetalhamento(
        json.inadimplenciaRepresentanteDetalhamento
      );
      setInadimplentes(json.inadimplentes);
      setInadimplentesDetalhamento(json.pagoRecebidosDetalhamento);

      setInadimplenciaAno(json.inadimplenciaAno);
      setInadimplenciaAno(json.inadimplenciaAnoDetalhamento);

      //----------------------------------------------------
      //-- Inadimplentes
      //----------------------------------------------------

      const originalCard1: any = [...json.inadimplencia];
      const groupLabelPie: Array<any> = [];

      // Itera sobre cada mês em originalCard1
      originalCard1.forEach((inadin: Inadimplencia) => {
        groupLabelPie.push(inadin.estado);
      });

      const groupValuePie: Array<any> = [];

      // Itera sobre cada mês em originalCard1
      originalCard1.forEach((inadin: Inadimplencia) => {
        groupValuePie.push(inadin.valor);
      });

      labelPie = groupLabelPie;
      //   setDetalhamentoInadimplencia(labelPie);

      valorPie = groupValuePie;

      setValorPie(valorPie);
      setLabelPie(labelPie);

      //----------------------------------------------------
      //-- Recebidos
      //----------------------------------------------------

      let labelPieRecebidos: string[] = [];
      let valorPieRecebidos: any[] = [];

      const recebidos: any = [...json.recebidos];
      const groupRecebidosValor: Array<any> = [];
      const groupRecebidosTipo: Array<any> = [];

      // Itera sobre cada mês em originalCard1
      recebidos.forEach((receb: Recebidos) => {
        groupRecebidosTipo.push(receb.tipo);
        groupRecebidosValor.push(receb.valor);
      });

      valorPieRecebidos = groupRecebidosValor;
      labelPieRecebidos = groupRecebidosTipo;

      console.log(groupRecebidosTipo);

      setValorPieRecebidos(valorPieRecebidos);
      setLabelRecebidos(labelPieRecebidos);

      //----------------------------------------------------
      //-- Recebidos de Cobrança
      //----------------------------------------------------

      let labelPieRecebidosDeCobranca: string[] = [];
      let valorPieRecebidosDeCobranca: any[] = [];

      const recebidosDeCobranca: any = [...json.recebidosDeCobranca];
      const groupRecebidosDeCobrancaValor: Array<any> = [];
      const groupRecebidosDeCobrancaTipo: Array<any> = [];

      // Itera sobre cada mês em originalCard1
      recebidosDeCobranca.forEach((receb: RecebidosDeCobranca) => {
        groupRecebidosDeCobrancaTipo.push(receb.tipo);
        groupRecebidosDeCobrancaValor.push(receb.valor);
      });

      valorPieRecebidosDeCobranca = groupRecebidosDeCobrancaValor;
      labelPieRecebidosDeCobranca = groupRecebidosDeCobrancaTipo;

      console.log(groupRecebidosDeCobrancaTipo);

      setValorPieRecebidosDeCobranca(valorPieRecebidosDeCobranca);
      setLabelRecebidosDeCobranca(labelPieRecebidosDeCobranca);

      //----------------------------------------------------
      //-- Pagos x recebidos
      //----------------------------------------------------

      let labelPiePagosRecebidos: string[] = [];
      let valorPiePagosRecebidos: any[] = [];

      const pagosRecebidos: any = [...json.pagoRecebidos];
      const groupPagosRecebidosValor: Array<any> = [];
      const groupPagosRecebidosTipo: Array<any> = [];

      // Itera sobre cada mês em originalCard1
      pagosRecebidos.forEach((receb: PagosRecebidos) => {
        groupPagosRecebidosTipo.push(receb.tipo);
        groupPagosRecebidosValor.push(receb.valor);
      });

      valorPiePagosRecebidos = groupPagosRecebidosValor;
      labelPiePagosRecebidos = groupPagosRecebidosTipo;

      console.log(valorPiePagosRecebidos);

      setValorPiePagosRecebidos(valorPiePagosRecebidos);
      setLabelPagosRecebidos(labelPiePagosRecebidos);

      //----------------------------------------------------
      //-- Inadimplencia Representante
      //----------------------------------------------------
      let labelPieInadimplenciaRepresentante: string[] = [];
      let valorPieInadimplenciaRepresentante: any[] = [];

      const inadimplenciaRepresentante: any = [
        ...json.inadimplenciaRepresentante,
      ];
      const groupInadimplenciaRepresentanteValor: Array<any> = [];
      const groupInadimplenciaRepresentanteTipo: Array<any> = [];

      // Itera sobre cada mês em originalCard1
      inadimplenciaRepresentante.forEach(
        (representante: InadimplenciaRepresentante) => {
          groupInadimplenciaRepresentanteTipo.push(representante.representante);
          groupInadimplenciaRepresentanteValor.push(representante.valor);
        }
      );

      valorPieInadimplenciaRepresentante = groupInadimplenciaRepresentanteValor;
      labelPieInadimplenciaRepresentante = groupInadimplenciaRepresentanteTipo;

      console.log(valorPieInadimplenciaRepresentante);
      console.log(labelPieInadimplenciaRepresentante);

      setValorPieInadimplenciaRepresentante(valorPieInadimplenciaRepresentante);
      setLabelPieInadimplenciaRepresentante(labelPieInadimplenciaRepresentante);

      //----------------------------------------------------
      //-- Inadimplencia Ano
      //----------------------------------------------------
      let labelPieInadimplenciaAno: string[] = [];
      let valorPieInadimplenciaAno: any[] = [];

      const inadimplenciaAno: any = [...json.inadimplenciaAno];
      const groupInadimplenciaAnoValor: Array<any> = [];
      const groupInadimplenciaAnoTipo: Array<any> = [];

      // Itera sobre cada mês em originalCard1
      inadimplenciaAno.forEach((ano: InadimplenciaAno) => {
        groupInadimplenciaAnoTipo.push(ano.descricao);
        groupInadimplenciaAnoValor.push(ano.valor);
      });

      valorPieInadimplenciaAno = groupInadimplenciaAnoValor;
      labelPieInadimplenciaAno = groupInadimplenciaAnoTipo;

      console.log(valorPieInadimplenciaAno);
      console.log(labelPieInadimplenciaAno);

      setValorPieInadimplenciaRepresentante(valorPieInadimplenciaAno);
      setLabelPieInadimplenciaRepresentante(labelPieInadimplenciaAno);
    }
  };

  //----------------------------------------------------
  //-- soma
  //----------------------------------------------------

  const somaRecebidosDeCobranca = () => {
    const dados: Detalhamento = recebidosDeCobrancaDetalhamento;
    somaDetalhamento(dados);
  };

  const somaRecebidos = () => {
    const dados: Detalhamento = recebidosDetalhamento;
    somaDetalhamento(dados);
  };

  const somaInadimplentes = () => {
    const dados: Detalhamento = inadimplenciaDetalhamento;
    somaDetalhamento(dados);
  };

  const somaPagosRecebidos = () => {
    const dados: Detalhamento = PagosRecebidosDetalhamento;
    somaDetalhamento(dados);
  };

  const somaDetalhamento = (dados: Detalhamento) => {
    const saldos = itensFiltrados.map((dados: Detalhamento) => dados.valor);
    if (saldos.length > 0) {
      const total = saldos.reduce((acc: any, saldo: any) => acc + saldo);
      return total;
    } else {
      return 0;
    }
  };

  const somaInadimplenciaRepresentante = () => {
    const dados: Detalhamento = PagosRecebidosDetalhamento;
    somaDetalhamento(dados);
  };

  const somaInadimplenciaAno = () => {
    const dados: Detalhamento = InadimplenciaAnoDetalhamento;
    somaDetalhamento(dados);
  };

  const handlerPie = (event: any) => {
    const pathElement = event.target; // Supondo que 'event.target' seja uma referência ao elemento <path> em questão
    const parentElement = pathElement.parentNode; // ou pathElement.parentElement;

    let label = parentElement.getAttribute('seriesName');
    console.log(label);
    console.log('hi + ' + label);
    console.log(inadimplenciaDetalhamento);

    // Use a função filter para criar um novo array com os itens que correspondem ao estado desejado
    const itensFiltrados = inadimplenciaDetalhamento.filter(
      (item: Detalhamento) => item.uf === label
    );

    // Exiba os itens filtrados
    console.log(label);
    console.log(itensFiltrados);

    setItensFiltrados(itensFiltrados);

    setShowModal(true);
  };

  const handlerPieRecebidos = (event: any) => {
    const pathElement = event.target; // Supondo que 'event.target' seja uma referência ao elemento <path> em questão
    const parentElement = pathElement.parentNode; // ou pathElement.parentElement;

    let label = parentElement.getAttribute('seriesName');
    removerAcentos(label);
    label = label.replace('x', ' ');
    console.log(label);
    console.log('hi + ' + label);
    console.log(recebidosDetalhamento);

    // Use a função filter para criar um novo array com os itens que correspondem ao estado desejado
    const itensFiltrados = recebidosDetalhamento.filter(
      (item: Detalhamento) => {
        return item.descricao ? item.descricao.includes(label) : false;
      }
    );

    // Exiba os itens filtrados
    console.log(label);
    console.log(itensFiltrados);

    setItensFiltrados(itensFiltrados);

    setShowModal(true);
  };

  const handlerPieRecebidosDeCobranca = (event: any) => {
    const pathElement = event.target; // Supondo que 'event.target' seja uma referência ao elemento <path> em questão
    const parentElement = pathElement.parentNode; // ou pathElement.parentElement;

    let label = parentElement.getAttribute('seriesName');
    removerAcentos(label);

    console.log('' + label);
    console.log(recebidosDeCobrancaDetalhamento);

    // Use a função filter para criar um novo array com os itens que correspondem ao estado desejado
    const itensFiltrados = recebidosDeCobrancaDetalhamento.filter(
      (item: Detalhamento) => {
        return item.descricao ? item.descricao.includes(label) : false;
      }
    );

    // Exiba os itens filtrados
    console.log(label);
    console.log(itensFiltrados);

    setItensFiltrados(itensFiltrados);

    setShowModal(true);
  };

  const handlerPiePagosRecebidos = (event: any) => {
    const pathElement = event.target; // Supondo que 'event.target' seja uma referência ao elemento <path> em questão
    const parentElement = pathElement.parentNode; // ou pathElement.parentElement;

    let label = parentElement.getAttribute('seriesName');
    removerAcentos(label);

    console.log('hi + ' + label);
    console.log(PagosRecebidosDetalhamento);

    // Use a função filter para criar um novo array com os itens que correspondem ao estado desejado
    const itensFiltrados = PagosRecebidosDetalhamento.filter(
      (item: Detalhamento) => {
        return item.descricao ? item.descricao.includes(label) : false;
      }
    );

    // Exiba os itens filtrados
    console.log(label);
    console.log(itensFiltrados);

    setItensFiltrados(itensFiltrados);

    setShowModal(true);
  };

  const handlerPieInadimplenciaRepresentante = (event: any) => {
    const pathElement = event.target; // Supondo que 'event.target' seja uma referência ao elemento <path> em questão
    const parentElement = pathElement.parentNode; // ou pathElement.parentElement;

    let label = parentElement.getAttribute('seriesName');
    removerAcentos(label);
    label = label.replaceAll('x', ' ');
    // label = label.replace('x', ' ');
    console.log(label);
    console.log(InadimplenciaRepresentanteDetalhamento);

    // Use a função filter para criar um novo array com os itens que correspondem ao estado desejado
    const itensFiltrados = InadimplenciaRepresentanteDetalhamento.filter(
      (item: Detalhamento) => {
        return item.representante ? item.representante.includes(label) : false;
      }
    );

    // Exiba os itens filtrados
    console.log(label);
    console.log(itensFiltrados);

    setItensFiltrados(itensFiltrados);

    setShowModal(true);
  };

  const handlerPieInadimplenciaAno = (event: any) => {
    const pathElement = event.target; // Supondo que 'event.target' seja uma referência ao elemento <path> em questão
    const parentElement = pathElement.parentNode; // ou pathElement.parentElement;

    let label = parentElement.getAttribute('seriesName');
    removerAcentos(label);
    label = label.replaceAll('x', ' ');
    // label = label.replace('x', ' ');
    console.log(label);
    console.log(InadimplenciaAnoDetalhamento);

    // Use a função filter para criar um novo array com os itens que correspondem ao estado desejado
    const itensFiltrados = InadimplenciaAnoDetalhamento.filter(
      (item: Detalhamento) => {
        return item.ano ? item.ano.includes(label) : false;
      }
    );

    // Exiba os itens filtrados
    console.log(label);
    console.log(itensFiltrados);

    setItensFiltrados(itensFiltrados);

    setShowModal(true);
  };

  const MyCard = ({ children }: any) => {
    return (
      <Col>
        <Style.CardDash border={theme} className="h-100">
          <Style.CardDashBody>{children}</Style.CardDashBody>
        </Style.CardDash>
      </Col>
    );
  };

  function removerAcentos(texto: string) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, texto);
  }

  return (
    <>
      <Row className="g-4">
        <Col md={12} lg={12}>
          <Row
            xs={1}
            md={2}
            className="g-4"
            key={Math.floor(Math.random() * 100)}
          >
            {/* <Col xs={1} md={2} className="g-4"> */}
            <MyCard>
              <Style.ContHorizontal>
                <Style.TextDash size={14} weight="bold">
                  Recebidos do Mês
                </Style.TextDash>
              </Style.ContHorizontal>
              <ChartPie
                options={pieOptions2(themeContext, labelPieRecebidos)}
                series={valorPieRecebidos}
                width={'80%'}
                onClick={(event) => handlerPieRecebidos(event)}
              />
            </MyCard>

            <MyCard>
              <Style.ContHorizontal>
                <Style.TextDash size={14} weight="bold">
                  Recebidos de Cobrança
                </Style.TextDash>
              </Style.ContHorizontal>
              <ChartPie
                options={pieOptions2(themeContext, labelPieRecebidosDeCobranca)}
                series={valorPieRecebidosDeCobranca}
                width={'80%'}
                onClick={(event) => handlerPieRecebidosDeCobranca(event)}
              />
            </MyCard>
            {/* <Col md={12}> */}
            <MyCard>
              <Style.ContHorizontal>
                <Style.TextDash size={14} weight="bold">
                  Pago x Recebidos
                </Style.TextDash>
              </Style.ContHorizontal>
              <ChartPie
                options={pieOptions2(themeContext, labelPiePagosRecebidos)}
                series={valorPiePagosRecebidos}
                width={'80%'}
                onClick={(event) => handlerPiePagosRecebidos(event)}
              />
            </MyCard>

            {/* <MyCard>
              <Style.ContHorizontal>
                <Style.TextDash size={14} weight="bold">
                  Despesas Pagas
                </Style.TextDash>
              </Style.ContHorizontal>
              <ChartPie
                options={pieOptions2(themeContext, labelPie2)}
                series={lineSeries}
                width={'80%'}
              />
            </MyCard> */}

            {/* <Col md={12} lg={12}> */}
            {/* <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '16px',
                }}
              > */}
            <MyCard>
              <Style.ContHorizontal>
                <Style.TextDash size={14} weight="bold">
                  Inadimplência por Estado
                </Style.TextDash>
              </Style.ContHorizontal>
              <ChartPie
                options={pieOptions(themeContext, labelPie)}
                series={valorPie}
                width={'80%'}
                onClick={(event) => handlerPie(event)}
              />
            </MyCard>

            <MyCard>
              <Style.ContHorizontal>
                <Style.TextDash size={14} weight="bold">
                  Inadimplência por Representante
                </Style.TextDash>
              </Style.ContHorizontal>
              <ChartPie
                options={pieOptions(
                  themeContext,
                  labelPieInadimplenciaRepresentante
                )}
                series={valorPieInadimplenciaRepresentante}
                width={'80%'}
                onClick={(event) => handlerPieInadimplenciaRepresentante(event)}
              />
            </MyCard>

            <MyCard>
              <Style.ContHorizontal>
                <Style.TextDash size={14} weight="bold">
                  Inadimplência por Ano
                </Style.TextDash>
              </Style.ContHorizontal>
              <ChartPie
                options={pieOptions(themeContext, labelPie)}
                series={valorPie}
                width={'80%'}
                onClick={(event) => handlerPie(event)}
              />
            </MyCard>
            {/* </div> */}
            {/* </Col> */}
            {/* </Col> */}
          </Row>
        </Col>
      </Row>

      {/* ----------------------------------------------------------------------------------------- */}

      <ModalComponent
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setItensFiltrados([]); // Limpa o array ao fechar o modal
        }}
        title={'Detalhamento'}
        Body={
          <div style={{ height: '600px' }}>
            <Style.TextDash size={18} weight="bold">
              <Style.ContHorizontal>
                <Style.TextDash size={14} weight="bold" style={{ width: '6%' }}>
                  NF
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '3%' }}>
                  Tipo
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  Parcela
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Razão Social
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '8%' }}>
                  Valor
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '10%' }}
                >
                  Saldo Juros
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '10%' }}
                >
                  Vencimento
                </Style.TextDash>

                <Style.TextDash
                  size={14}
                  weight="bold"
                  style={{ width: '15%' }}
                >
                  Representante
                </Style.TextDash>

                <Style.TextDash size={14} weight="bold" style={{ width: '5%' }}>
                  UF
                </Style.TextDash>
              </Style.ContHorizontal>
            </Style.TextDash>

            <Style.Separator />
            <div style={{ height: 500, overflow: 'auto' }}>
              {itensFiltrados.map((item: any) => {
                return (
                  <>
                    <col></col>
                    <Style.ContHorizontal style={{ marginTop: '9px' }}>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '9%', paddingRight: '4px' }}
                      >
                        <tr>{item.nf}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '5%', paddingRight: '1px' }}
                      >
                        <tr>{item.tipo}</tr>
                      </Style.TextDash>
                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '2%', paddingRight: '80px' }}
                      >
                        <tr>{item.parcela}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '20%', paddingRight: '10px' }}
                      >
                        {<tr>{removerAcentos(item.razaoSocial)}</tr>}
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '14%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(item.valor)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '10%', paddingRight: '5px' }}
                      >
                        <tr>{CurrencyFormat(item.saldoJuros)}</tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '10%' }}
                      >
                        <tr>
                          {capitalize(
                            item.vencimento.slice(6, 8) +
                              '/' +
                              item.vencimento.slice(4, 6) +
                              '/'
                          ) + item.vencimento.slice(0, 4)}
                        </tr>
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '20%', paddingRight: '5' }}
                      >
                        {<tr>{removerAcentos(item.representante)}</tr>}
                      </Style.TextDash>

                      <Style.TextDash
                        size={14}
                        weight="normal"
                        style={{ width: '4%', paddingRight: '5px' }}
                      >
                        {<tr>{item.uf}</tr>}
                      </Style.TextDash>
                    </Style.ContHorizontal>
                    <Style.Separator />
                  </>
                );
              })}
            </div>

            <Style.ContHorizontalTotal>
              <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                Total Geral:
              </Style.TextDash>
              <Style.TextDash size={18} weight="bold" paddingRigh={20}>
                {CurrencyFormat(somaDetalhamento(itensFiltrados))}
              </Style.TextDash>
            </Style.ContHorizontalTotal>
          </div>
        }
        Tools={''}
      />
    </>
  );
};

export default FinanceiroMes;
